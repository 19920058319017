import React from 'react';
import classNames from 'classnames';
import { Settings, ViewerBoardField, ViewerAsset } from './types';
import { ColumnSettings } from '@gorilla/widgets-shared/src/schemas';
import { formatNumber } from './utils';

type AssetsById = Record<string, ViewerAsset>;

export const IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp'];

type ValueProps = {
  field: ViewerBoardField;
  value: any;
  settings: Settings;
  mode: 'preview' | 'publish';
  widgetId: string;
  assetsById: AssetsById;
  columnSettings: ColumnSettings[keyof ColumnSettings];
  previewTumbnailGeneratorUrl?: string;
};

function BoardRelationValue({ value }: ValueProps) {
  if (value !== '') {
    return <span className="value board_relation">{value}</span>;
  }
}

function CheckboxValue({ value }: ValueProps) {
  if (value === 'v') {
    return (
      <span className="value checkbox">
        <i className="fa-solid fa-circle-check"></i>
      </span>
    );
  } else {
    return (
      <span className="value checkbox">
        <i className="fa-regular fa-circle"></i>
      </span>
    );
  }
}

function ButtonValue({ value }: ValueProps) {
  if (value !== '') {
    return <span className="value button">{value}</span>;
  }
}

function ColorPickerValue({ value }: ValueProps) {
  if (value !== null) {
    return (
      <span className="value color_picker">
        <i className="fa-solid fa-circle" style={{ color: `${value}` }}></i>
        {value}
      </span>
    );
  }
}

function CountryValue({ value }: ValueProps) {
  if (value !== null) {
    return <span className="value country">{value}</span>;
  }
}

// dateTime can have the following formats: YYYY-MM-DD, YYYY-MM-DD HH:MM:SS
// this function should  return an object with the following properties: year, month, day, hour, minute, second
function parseDateTime(dateTime: string) {
  const [date, time] = dateTime.split(' ');
  const [year, month, day] = date.split('-');
  const [hour, minute, second] = time ? time.split(':') : [undefined, undefined, undefined];

  return {
    year,
    month,
    day,
    hour,
    minute,
    second,
  };
}

function time24hTo12h(time24h: string) {
  const [hour, minute] = time24h.split(':');
  const h = +hour;
  const m = +minute;
  const period = h >= 12 ? 'pm' : 'am';
  const h12 = h % 12 || 12;

  return `${h12}${m ? `:${minute}` : ''}${period}`;
}

function DateValue({ columnSettings, value }: ValueProps) {
  if (value !== '') {
    let v = value;
    const pieces = v.split(' ');
    const time24h = pieces.length > 1 ? pieces[1] : null;
    const hasTime = time24h !== null;

    const dateFormat = columnSettings.dateFormat || 'YYYY-MM-DD';
    const timeFormat = columnSettings.timeFormat || '24h';

    if (dateFormat === 'MM/DD/YYYY') {
      try {
        const { month, day, year } = parseDateTime(v);

        v = `${month}/${day}/${year}${hasTime ? ` ${time24h}` : ''}`;
      } catch (err) {}
    } else if (dateFormat === 'DD-MM-YYYY') {
      try {
        const { month, day, year } = parseDateTime(v);

        v = `${day}-${month}-${year}${hasTime ? ` ${time24h}` : ''}`;
      } catch (err) {}
    } else if (dateFormat === 'DD/MM/YYYY') {
      try {
        const { month, day, year } = parseDateTime(v);

        v = `${day}/${month}/${year}${hasTime ? ` ${time24h}` : ''}`;
      } catch (err) {}
    }

    if (time24h && timeFormat === '12h') {
      const time12h = time24hTo12h(time24h);
      const timePieces = v.split(' ');

      v = `${timePieces[0]} ${time12h}`;
    }

    return (
      <span className="value date">
        <i className="fa-regular fa-calendar"></i>
        {v}
      </span>
    );
  }
}

function DependencyValue({ value }: ValueProps) {
  if (value !== '') {
    return <span className="value dependency">{value}</span>;
  }
}
{
  /* 
function DropdownValue({ value }: ValueProps) {
  if (value !== '') {
    return <span className="value dropdown">{value}</span>;
  }
}
*/
}
function DropdownValue({ value }: ValueProps) {
  if (value !== null) {
    return (
      <span className="value dropdown">
        {(value.split(', ') as string[])
          .map((t) => t.trim())
          .reduce((prev, curr, index) => {
            return index === 0 ? [<strong key={curr}>{curr}</strong>] : [...prev, <strong key={curr}>{curr}</strong>];
          }, [] as React.ReactNode[])}
      </span>
    );
  } else {
    return (
      <span className="value dropdown empty">
        <strong>&nbsp;</strong>
      </span>
    );
  }
}

function TimeTrackingValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value time_tracking">
        <i className="fa-regular fa-clock"></i>
        {value}
      </span>
    );
  }
}

function EmailValue({ value }: ValueProps) {
  if (value && value.email) {
    return (
      <span className="value email">
        <i className="fa-regular fa-envelope"></i>
        <a href={`mailto:${value.email}`}>{value.label}</a>
      </span>
    );
  }
}

function FileValue({ value, mode, widgetId, assetsById, settings, field, previewTumbnailGeneratorUrl }: ValueProps) {
  const columnSettings = settings.columnSettings[field.id] || {};
  const filesDisplayMode = columnSettings.filesDisplayMode || 'list';
  const assets = ((value.asset_ids || []).map((assetId: string) => assetsById[assetId]) as ViewerAsset[]).filter((asset) => asset);

  if (filesDisplayMode === 'thumbs') {
    return (
      <ul className="downloads--thumbs">
        {assets.map((asset) => {
          const assetUrlPublished = `https://widget-assets.getgorilla.app/${widgetId}/assets/${asset.id}/${asset.name}`;
          const assetUrl = mode === 'publish' ? assetUrlPublished : asset.public_url;
          const isImage = IMAGE_EXTENSIONS.includes(asset.extension);
          let thumbnailUrl: null | string = null;

          if (isImage && asset._thumbnails && asset._thumbnails['1:1_SMALL']) {
            thumbnailUrl = `https://thumbnails.getgorilla.app${asset._thumbnails['1:1_SMALL']}`;
          } else if (isImage && previewTumbnailGeneratorUrl) {
            thumbnailUrl = `${previewTumbnailGeneratorUrl}?mode=1:1_SMALL&source=${encodeURIComponent(asset.public_url!)}`;
          }

          return (
            <li>
              <span className="value download">
                <a href={assetUrl} target="_blank" title={asset.name}>
                  {thumbnailUrl ? (
                    <div className="download--image">
                      <img src={thumbnailUrl} width="30" height="30" alt="" style={{ aspectRatio: 1, objectFit: 'cover' }} />
                    </div>
                  ) : (
                    <div className="download--any">
                      <i className="fa-regular fa-file"></i>
                    </div>
                  )}
                </a>
              </span>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <ul className="downloads--list">
      {assets.map((asset) => {
        const assetUrlPublished = `https://widget-assets.getgorilla.app/${widgetId}/assets/${asset.id}/${asset.name}`;
        const assetUrl = mode === 'publish' ? assetUrlPublished : asset.public_url;

        return (
          <li>
            <span className="value download">
              <i className="fa-regular fa-file"></i>
              <a href={assetUrl} target="_blank" title={asset.name}>
                {asset.name}
              </a>
            </span>
          </li>
        );
      })}
    </ul>
  );
}

function FormulaValue({}: ValueProps) {
  return (
    <span className="value formula">
      <i className="fa-regular fa-function"></i>
    </span>
  );
}

function HourValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value hour">
        <i className="fa-regular fa-clock"></i>
        {value}
      </span>
    );
  }
}

function LinkValue({ value }: ValueProps) {
  if (value && value.url) {
    return (
      <span className="value link">
        <i className="fa-regular fa-arrow-up-right-from-square"></i>
        <a href={`${value.url}`} target="_blank">
          {value.label}
        </a>
      </span>
    );
  }
}

function LocationValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value location">
        <i className="fa-solid fa-location-dot"></i>
        <a href={`http://maps.google.com/maps?q=${value}`} target="_blank">
          Google Maps
        </a>
      </span>
    );
  }
}

function LongTextValue({ value }: ValueProps) {
  return <span className="value long_text">{value}</span>;
}

function MirrorValue({ value }: ValueProps) {
  if (value !== '') {
    return <span className="value mirror">{value}</span>;
  }
}

function PeopleValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value people">
        <i className="fa-solid fa-circle-user"></i>
        {value}
      </span>
    );
  }
}

function NumbersValue({ field, value }: ValueProps) {
  const settings = field.settings;
  let formatedValue = value ? formatNumber(+value) : value;

  if (settings?.unit) {
    // @ts-ignore
    const unit = settings?.unit?.custom_unit || settings?.unit?.symbol;
    // @ts-ignore
    const direction = settings?.unit?.direction;

    formatedValue = direction === 'left' ? `${unit}${formatedValue} ` : `${formatedValue}${unit}`;
  }

  if (value !== '') {
    return <span className="value numbers">{formatedValue}</span>;
  }
}

function PhoneValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value phone">
        <i className="fa-regular fa-phone"></i>
        <a href={`tel:+${value}`}>+{value}</a>
      </span>
    );
  }
}

function ItemIdValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value item_id">
        <i className="fa-regular fa-fingerprint"></i>
        {value}
      </span>
    );
  }
}

function CreationLogValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value creation_log">
        <i className="fa-regular fa-calendar-clock"></i>
        {value}
      </span>
    );
  }
}

function LastUpdatedValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value last_updated">
        <i className="fa-regular fa-calendar-clock"></i>
        {value}
      </span>
    );
  }
}

function RatingValue({ value }: ValueProps) {
  let rating = Math.max(0, Math.min(5, value));
  let stars = [];

  for (let i = 1; i <= 5; i++) {
    const isActive = i <= rating;
    stars.push(<i key={i} className={`fa-star ${isActive ? 'fa-solid' : 'fa-regular'}`}></i>);
  }

  return <span className="value rating">{stars}</span>;
}

function StatusValue({ value }: ValueProps) {
  if (value !== null) {
    return (
      <span className="value status">
        <strong>{value}</strong>
      </span>
    );
  } else {
    return (
      <span className="value status empty">
        <strong>&nbsp;</strong>
      </span>
    );
  }
}

function TagsValue({ value }: ValueProps) {
  if (value !== null) {
    return (
      <span className="value tags">
        {(value.split(', ') as string[])
          .map((t) => t.trim())
          .reduce((prev, curr, index) => {
            return index === 0 ? [<strong key={curr}>{curr}</strong>] : [...prev, <strong key={curr}>{curr}</strong>];
          }, [] as React.ReactNode[])}
      </span>
    );
  } else {
    return (
      <span className="value tags empty">
        <strong>&nbsp;</strong>
      </span>
    );
  }
}

function TextValue({ value }: ValueProps) {
  if (value !== '') {
    return <span className="value text">{value}</span>;
  }
}

function TimelineValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value timeline">
        <strong>{value}</strong>
      </span>
    );
  } else {
    return (
      <span className="value timeline empty">
        <strong>&nbsp;</strong>
      </span>
    );
  }
}

function WorldClockValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value world_clock">
        <i className="fa-regular fa-house-night"></i>
        {value}
      </span>
    );
  }
}

function VoteValue({ value }: ValueProps) {
  if (value !== null) {
    return (
      <span className="value vote">
        <strong>{value}</strong>
      </span>
    );
  } else {
    return (
      <span className="value vote empty">
        <strong>&nbsp;</strong>
      </span>
    );
  }
}

function WeekValue({ value }: ValueProps) {
  if (value !== '') {
    return (
      <span className="value week">
        <strong>{value}</strong>
      </span>
    );
  } else {
    return (
      <span className="value week empty">
        <strong>&nbsp;</strong>
      </span>
    );
  }
}

function GroupValue({ value }: ValueProps) {
  if (value !== null) {
    return (
      <span className="value group">
        <strong>{value}</strong>
      </span>
    );
  } else {
    return (
      <span className="value group empty">
        <strong>&nbsp;</strong>
      </span>
    );
  }
}

function isEmptyValue(value: any) {
  if (value === null || value === undefined || value === '') {
    return true;
  }

  if (typeof value === 'object' && value.constructor === Object && 'text' in value && value.text === '') {
    return true;
  }

  return false;
}

const VALUE_COMPONENTS: Record<string, any> = {
  board_relation: BoardRelationValue,
  checkbox: CheckboxValue,
  button: ButtonValue,
  color_picker: ColorPickerValue,
  country: CountryValue,
  date: DateValue,
  dependency: DependencyValue,
  dropdown: DropdownValue,
  time_tracking: TimeTrackingValue,
  email: EmailValue,
  file: FileValue,
  formula: FormulaValue,
  hour: HourValue,
  link: LinkValue,
  location: LocationValue,
  long_text: LongTextValue,
  mirror: MirrorValue,
  people: PeopleValue,
  numbers: NumbersValue,
  phone: PhoneValue,
  item_id: ItemIdValue,
  creation_log: CreationLogValue,
  last_updated: LastUpdatedValue,
  rating: RatingValue,
  status: StatusValue,
  tags: TagsValue,
  text: TextValue,
  timeline: TimelineValue,
  world_clock: WorldClockValue,
  vote: VoteValue,
  week: WeekValue,
  group: GroupValue,
};

export function Value({ field, value, settings, mode, widgetId, assetsById, previewTumbnailGeneratorUrl }: ValueProps) {
  const Component = VALUE_COMPONENTS[field.type];
  const columnSettings = settings.columnSettings[field.id] || {};

  let label = null;
  let valueRendered = null;

  if (!isEmptyValue(value)) {
    if (Component) {
      valueRendered = (
        <Component
          field={field}
          value={value}
          settings={settings}
          mode={mode}
          widgetId={widgetId}
          assetsById={assetsById}
          previewTumbnailGeneratorUrl={previewTumbnailGeneratorUrl}
          columnSettings={columnSettings}
        />
      );
    } else {
      valueRendered = <span className="value">{value}</span>;
    }
  }

  if (valueRendered && columnSettings.showLabel) {
    label = <strong>{field.label}</strong>;
  }

  return (
    <div className={classNames('spanholder', { 'spanholder--label': label, 'spanholder--inline': columnSettings.labelMode === 'inline' })}>
      {label}
      {valueRendered}
    </div>
  );
}
